$(function () {
	$('#logo_carousel').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		asNavFor: '#review2',
		centerMode: true,
		focusOnSelect: true,
		responsive: [{
				breakpoint: 1570,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 720,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});
	$('#review2').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '#logo_carousel',
		responsive: [{
			breakpoint: 1570,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			}
		},
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 960,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 720,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
	});

	$(".read").click(function () {
		$("#review2").toggleClass("main");
	});

	$(".cross").click(function () {
		$("#review2").toggleClass("main");
	});



});

AOS.init({
	duration: 850,
	easing: 'ease-in-sine',
	delay: 70,
});